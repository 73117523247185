// @mui material components
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import folder from "assets/images/folder.svg";
import bgImage from "assets/images/illustrations/illustration-resetttttt.jpg"
import BuiltByDevelopers from "pages/Presentation/components/BuiltByDevelopers";


function Docs() {

  const [isZoomed, setIsZoomed] = useState(false);
  const [isZoomed1, setIsZoomed1] = useState(false);
  const [isZoomed2, setIsZoomed2] = useState(false);

  const handleMouseEnter1 = () => {
    setIsZoomed1(true);
  };

  const handleMouseLeave1 = () => {
    setIsZoomed1(false);
  };


  const handleMouseEnter = () => {
    setIsZoomed(true);
  };

  const handleMouseLeave = () => {
    setIsZoomed(false);
  };

  const imageStyle = {
    maxWidth: '100%',
    borderRadius: '100%',
    transition: 'transform 0.3s ease',
    transform: isZoomed ? 'scale(1.3)' : 'scale(1)',
  };
  const imageStyle1 = {
    maxWidth: '100%',
    borderRadius: '100%',
    transition: 'transform 0.3s ease',
    transform: isZoomed1 ? 'scale(1.3)' : 'scale(1)',
  };

  const handleMouseEnter2 = () => {
    setIsZoomed2(true);
  };

  const handleMouseLeave2 = () => {
    setIsZoomed2(false);
  };

  const imageStyle2 = {
    maxWidth: '100%',
    borderRadius: '100%',
    transition: 'transform 0.3s ease',
    transform: isZoomed2 ? 'scale(1.3)' : 'scale(1)',
  };

  return (
    <Grid
      zIndex={5}
      pt="10vh"
      sx={{
        backgroundColor: ({ palette: { info }, functions: { rgba } }) =>
          rgba(info.main, 0.08), // Cambia el valor de 0.8 a 0.6 para hacerlo más oscuro
        backdropFilter: "blur(30px)",

      }}
    >
      <Grid xs={12} mx="auto">
        <MKBox textAlign="center" mb={12}>
          <Grid>
            <Grid
              item
              xs={12}
              sx={{
                mb: 6,
                pt: 6
              }}
            >
              <MKTypography
                variant="h2"
                color="info"
                sx={{
                  textAlign: "center",
                  mb: 3,
                }}
              >
                Marco legal y reglamento{" "}
              </MKTypography>{" "}
              <MKTypography
                variant="body1"
                color="dark"
                opacity={0.8}
                sx={{
                  textAlign: "center",
                }}
              >
                Para más información sobre las disposiciones oficiales, dejamos a su alcance los siguientes documentos.
              </MKTypography>{" "}
            </Grid>{" "}
          </Grid>{" "}
          <MKBox component="section" >
            <MKBox
              display="flex"
              alignItems="center"
              my={5}
              py={6}
              sx={{
                borderRadius: 5,
                overflow: "hidden", 
                margin: "0 auto", 
                width: "80%",
                backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                  `${linearGradient(
                    rgba(gradients.info.main, 0.3),
                    rgba(gradients.light.state, 0.8)
                  )}, url(${bgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "middle",
              }}
            >
              ;
              <Grid container spacing={3} justifyContent="center">
                <Grid item lg={2} xs={6}>
                  <Tooltip title="Marco Legal Federal">
                    <MKBox
                      opacity={0.8}
                      component="a"
                      href="http://www.diputados.gob.mx/LeyesBiblio/ref/lft.htm"
                      target="_blank"
                    >
                      <MKBox
                        style={imageStyle}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        component="img"
                        src={folder}
                        width="50%"
                      />
                      <MKTypography color="dark" variant="body2" sx={{ fontWeight: "regular" }}>Marco legal federal</MKTypography>
                    </MKBox>
                  </Tooltip>
                </Grid>
                <Grid item lg={2} xs={6}>
                  <Tooltip title="Normas y reglamentos mexicanos en salud laboral">
                    <MKBox
                      opacity={0.8}
                      component="a"
                      href="http://asinom.stps.gob.mx:8145/Centro/CentroMarcoNormativo.aspx"
                      target="_blank"
                    >
                      <MKBox
                        style={imageStyle1}
                        onMouseEnter={handleMouseEnter1}
                        onMouseLeave={handleMouseLeave1}
                        component="img"
                        src={folder}
                        width="50%"
                      />
                      <MKTypography color="dark" variant="body2" sx={{ fontWeight: "regular" }}>Normas y reglamentos en salud laboral</MKTypography>
                    </MKBox>
                  </Tooltip>
                </Grid>
                <Grid item lg={2} xs={6}>
                  <Tooltip title="Reglamento federal">
                    <MKBox
                      opacity={0.8}
                      component="a"
                      href="/"
                      target="_blank"
                    >
                      <MKBox
                        style={imageStyle2}
                        onMouseEnter={handleMouseEnter2}
                        onMouseLeave={handleMouseLeave2}
                        component="img"
                        src={folder}
                        width="50%"
                      />
                      <MKTypography color="dark" variant="body2" sx={{ fontWeight: "regular" }}>Reglamento federal</MKTypography>
                    </MKBox>
                  </Tooltip>
                </Grid>
              </Grid>
            </MKBox>
          </MKBox>
        </MKBox>
        <BuiltByDevelopers />
      </Grid>
    </Grid>
  );
}

export default Docs;
