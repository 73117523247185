
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKTypography from "components/MKTypography";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import {
  Card,
  Divider,
  CardContent,
  Typography,
  CardHeader,
} from "@mui/material";

import bgImage from "assets/images/bg3.jpg"
import us from "assets/images/bg4.jpg"

function Featuring() {
  const blueColor = 'f0f0f0'; // Color azul

  return (
    <Grid >
      <Grid
        zIndex={5}
        my="auto"
        pt="10vh"
        minHeight="90vh"
        sx={{
          backgroundColor: ({ palette: { info }, functions: { rgba } }) =>
            rgba(info.main, 0.08), // Cambia el valor de 0.8 a 0.6 para hacerlo más oscuro
          backdropFilter: "blur(30px)",

        }}
      >
        <Grid
          item
          xs={12}
          mx={2}
          sx={{
            mb: 6,
          }}
          id="politicas"
        >
          <MKTypography
            variant="h2"
            color="info"
            sx={{
              textAlign: "center",
              mb: 3,
            }}
          >
            Conoce sobre nuestros principios
          </MKTypography>{" "}
          <MKTypography
            variant="body1"
            color="dark"
            opacity={0.8}
            sx={{
              textAlign: "center",
            }}
          >
            Dejamos a tu disposición nuestra misión, visión y políticas empresariales.{" "}
          </MKTypography>{" "}
        </Grid>{" "}
        <Grid >
          <MKBox
            variant="gradient"
            bgColor="info"
            position="relative"
            sx={{
              overflow: "hidden",
            }}
          >
            <MKBox
              component="img"
              src={bgImage}
              alt="pattern-lines"
              sx={{
                backgroundAttachment: "fixed", // Fondo estático
              }}
              position="absolute"
              width="100%"
              zIndex={1}
              opacity={0.5}
            />{" "}
            <Container
              sx={{
                position: "relative",
                zIndex: 2,
              }}
            >
              <Grid
                container
                item
                spacing={4}
                xs={12}
                md={12}
                py="9vh"
              >
                <Grid item xs={12} lg={4}>
                  <Card
                   elevation={12} // Añadir sombreado
                   sx={{
                    height: "30vh",
                    borderRadius: 10, // Ajustar redondeo de los bordes según tus preferencias
                     boxShadow: "2px 2px 5px #003FA6"
                   }}
                  >
                    <CardHeader
                      align="center"
                      title={
                        <Typography
                          variant="h3"
                          color="textInfo"
                          align="center"
                          component="p"
                        >
                          Misión
                        </Typography>
                      }
                    />{" "}
                    <CardContent>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold" }}
                        color="textSecondary"
                        component="p"
                        my={2}
                        textAlign="justify"
                      >
                        Proporcionar un adecuado servicio de salud ocupacional a las empresas acorde a las normativas vigentes del sistema de salud en México.
                      </Typography>{" "}
                    </CardContent>{" "}
                  </Card>{" "}
                </Grid>{" "}
                <Grid item xs={12} lg={4}>
                  <Card
                   elevation={12} // Añadir sombreado
                   sx={{
                    height: "30vh",
                    borderRadius: 10, // Ajustar redondeo de los bordes según tus preferencias
                     boxShadow: "2px 2px 5px #003FA6"
                   }}
                  >
                    <CardHeader
                      align="center"
                      title={
                        <Typography
                          variant="h3"
                          color="textInfo"
                          align="center"
                          component="p"
                        >
                          Visión
                        </Typography>
                      }
                    />{" "}
                    <CardContent>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold" }}
                        color="textSecondary"
                        component="p"
                        textAlign="justify"
                      >
                        Ser una empresa líder en salud ocupacional a nivel estatal y federal con la mira a superar las barreras.
                      </Typography>{" "}
                    </CardContent>{" "}

                  </Card>{" "}
                </Grid>{" "}
                <Grid item xs={12} lg={4}>
                  <Card
                  elevation={12} // Añadir sombreado
                  sx={{
                   height: "30vh",
                   borderRadius: 10, // Ajustar redondeo de los bordes según tus preferencias
                   boxShadow: "2px 2px 5px #003FA6"                                    
                   }}
                  >
                    <CardHeader
                      align="center"
                      title={
                        <Typography
                          variant="h3"
                          color="textInfo"
                          align="center"
                          component="p"
                        >
                          Política de calidad
                        </Typography>
                      }
                    />{" "}
                    <CardContent>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold" }}
                        color="textSecondary"
                        component="p"
                        my={2}
                        textAlign="justify"
                      >
                        Implementar servicios de Salud Laboral especializados que ayuden a organizaciones y empresas a mantener su población de trabajadores sanos y con un buen estilo de vida.
                      </Typography>{" "}
                    </CardContent>{" "}
                  </Card>{" "}
                </Grid>{" "}
              </Grid>{" "}
            </Container>{" "}
          </MKBox>{" "}
        </Grid>{" "}
      </Grid>
      <Divider style={{ backgroundColor: blueColor, height: 0, marginTop: 50, marginBottom: 50 }} />
      <Grid
        item
        xs={12}
        md={0}
        mx={1}
        sx={{
          mb: 6,
        }}
      >
        <MKTypography
          variant="h2"
          color="info"
          sx={{
            textAlign: "center",
            mb: 3,
          }}
        >
          Nuestro servicio
        </MKTypography>{" "}
        <MKTypography
          variant="body1"
          color="dark"
          opacity={0.8}
          sx={{
            textAlign: "center",
          }}
        >
          Conoce sobre nuestro servicio y la manera en que operamos.{" "}
        </MKTypography>{" "}
      </Grid>{" "}
      <MKBox
        minHeight="35rem"
        opacity={0.8}
        width="100%"
        sx={{
          backgroundImage: `url(${us})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid container>
            <Grid item md={7} xs={12}>
              <Card variant="outlined" >
                <Grid container className="side-by-side-boxes" position="relative">
                  <Grid item xs={12} md={12}>
                    <MKBox>
                      <MKTypography
                        variant="h4"
                        color="info"
                        pt={2}
                        sx={{
                          lineHeight: 2,
                          textAlign: "center",
                        }}
                      >
                        Forma en que opera nuestra empresa
                      </MKTypography>
                      <MKTypography
                        variant="body2"
                        color="gray"
                        p={4}
                        sx={{
                          lineHeight: 2,
                          fontWeight: "regular",
                          textAlign: "justify",
                        }}
                      >
                        Dentro de nuestras labores, nos encargamos de cuidar la salud de los empleados, identificando y abordando los posibles efectos negativos que el trabajo puede tener en su salud física y mental. Esto incluye exámenes médicos periódicos, evaluación de riesgos laborales, consejos de prevención, tratamiento de lesiones laborales y el cumplimiento de regulaciones de salud y seguridad en el lugar de trabajo.
                      </MKTypography>
                    </MKBox>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>{" "}
    </MKBox>
    </Grid>
  );
}

export default Featuring;
