/* eslint-disable react/button-has-type */
import React from "react";
import { Container } from 'react-bootstrap';

function WhatsAppButton() {

  // Handle de procedimientos para enviar mensaje via whatsapp
  const handleWhatsAppClick = () => {

    // Reemplaza  con el número de teléfono de WhatsApp al que deseas redirigir.
    const phoneNumber = "6181128866";

    // Reemplaza con el mensaje que desees enviar en formato URL.
    const message = "Hola,%20necesito%20mas%20informacion";

    // Crea la URL de WhatsApp con el número y el mensaje (opcional).
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;

    // Redirige a WhatsApp cuando se hace clic en el botón.
    window.open(whatsappURL, "_blank");
  };

  // Handle de procedimientos para enviar mensaje via messenger
  const handleMessengerClick = () => {

    // Reemplaza con el ID de usuario o nombre de usuario de Facebook Messenger.
    const recipientID = "100064278240012";

    // Crea la URL de Messenger con el ID del destinatario.
    const messengerURL = `https://m.me/${recipientID}`;

    // Redirige a Facebook Messenger cuando se hace clic en el botón.
    window.open(messengerURL, "_blank");
  };

  return (
    <Container>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
          <button target="_blank" className='btn btn-success text-white mx-2 py-3' onClick={handleWhatsAppClick} style={{ width: "100%", maxWidth: "400px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <i className="fab fa-whatsapp" style={{ fontSize: "25px" }} />
              <span style={{ marginLeft: "8px", textAlign: "left" }}>Solicitar más información por WhatsApp</span>
            </div>
          </button>
          <button rel="noreferrer" className='btn btn-primary text-white mx-2 mt-3 py-3' href={handleMessengerClick} style={{ width: "100%", maxWidth: "400px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
             <i className="fab fa-facebook-messenger"  style={{ fontSize: "25px" }} />
              <span style={{ marginLeft: "8px", textAlign: "left" }}>Solicitar más información por correo</span>
            </div>
        </button>
        </div>
    </Container>

  );
}

export default WhatsAppButton;
