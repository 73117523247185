import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Divider } from "@mui/material";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";
import MKTypography from "components/MKTypography";

// Images
import bgImage from "assets/images/bg1.jpg";
import Whatsapi from "./whatsapi";
import Maps from "./maps";
import Cont from "./cont";
import WhatsAppButton from "./whatsbtn";
import logo from "../../../../assets/images/logos/gray-logos/logols3.png"

function Contacto() {
  const visibleRoutes = routes.filter((route) => !route.hidden);
  const blueColor = "f0f0f0"; // Color azul

  return (
    <>
      <DefaultNavbar
        action={{
          type: "internal",
          route: "/contacto",
          label: "CONTACTANOS",
          color: "info",
        }}
        routes={visibleRoutes}
        sticky
      />
      <MKBox
        minHeight="40vh"
        opacity={0.8}
        width="100%"
        sx={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundAttachment: "fixed", // Fondo estático
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container />
      </MKBox>{" "}
      <Card
        sx={{
          mx: {
            xs: 2,
            lg: 3,
          },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid
          item
          xs={12}
          mt={2}
          mx={2}
          sx={{
            mb: 12,
          }}
        >
          <MKTypography
            variant="h1"
            color="info"
            sx={{
              textAlign: "center",
              mb: 3,
              mt: 5,
            }}
          >
            Contactanos{" "}
          </MKTypography>{" "}
          <MKTypography
            variant="h5"
            color="dark"
            opacity={0.8}
            sx={{
              textAlign: "center",
            }}
          >
            Dejamos a tu disposición un mapa con nuestra ubicación.{" "}
          </MKTypography>{" "}
        </Grid>{" "}
        <Maps />
        <Divider
          style={{
            backgroundColor: blueColor,
            height: 0,
            marginTop: 10,
            marginBottom: 100,
          }}
        />{" "}
        <MKBox
          zIndex={5}
          sx={{
            backgroundColor: ({ palette: { info }, functions: { rgba } }) =>
              rgba(info.main, 0.08), // Cambia el valor de 0.8 a 0.6 para hacerlo más oscuro
            backdropFilter: "blur(30px)",
            paddingY: "10vh",
            alignItems: "center", // Centra verticalmente
            justifyContent: "center", // Centra horizontalmente
          }}
        >
          <Grid
            item
            mx={1}
            xs={12}
            sx={{
              mb: 6,
            }}
          >
            <MKTypography
              variant="h2"
              color="info"
              sx={{
                textAlign: "center",
                mb: 2,
                mt: 1,
              }}
            >
              Siguenos en Facebook{" "}
            </MKTypography>{" "}
            <MKTypography
              variant="body1"
              color="dark"
              opacity={0.8}
              sx={{
                textAlign: "center",
              }}
            >
              Conoce las últimas novedades de nuestra página oficial de Facebook.{" "}
            </MKTypography>{" "}
          </Grid>{" "}
          <Container>
            <Card
              zIndex={5}
              elevation={5}
              sx={{
                backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
                  rgba(white.main, 0.8),
                backdropFilter: "blur(30px)",
                boxShadow: "0px 0px 5px #71A7FF",
                borderRadius: 5,
                display: "flex",
                padding: "4%",
                alignItems: "center", // Centra verticalmente
                justifyContent: "center", // Centra horizontalmente
              }}
            >
              <Grid container spacing={5} id="mensaje">
                <Grid item xs={12} lg={7} mx={2}>
                  <MKTypography
                    variant="h5"
                    color="info"
                    opacity={0.8}
                    sx={{
                      textAlign: "justify",
                    }}
                  >
                    Siguenos en nuestra página de Facebook y mantente el día con las últimas novedades
                    en nuestros beneficios y convenios.{" "}
                  </MKTypography>{" "}
                  <MKTypography
                    variant="body1"
                    color="dark"
                    opacity={0.8}
                    my={6}
                    sx={{
                      textAlign: "justify",
                    }}
                  >
                    Tambien dejamos a tu disposición nuestro chat en WhatApp y FB Messenger para mayor comodidad.{" "}
                  </MKTypography>{" "}
                  <Grid>
                    <WhatsAppButton />
                  </Grid>{" "}
                </Grid>{" "}
                <Grid item xs={12} lg={4}>
                  <Whatsapi />
                </Grid>{" "}
              </Grid>{" "}
            </Card>
          </Container>
        </MKBox>{" "}
        <Divider
          style={{
            backgroundColor: blueColor,
            height: 0,
            marginTop: 10,
            marginBottom: 50,
          }}
        />{" "}
        <Grid
          item
          xs={12}

          id="contacto"
        >
          <Container>
            <MKTypography
              variant="h2"
              color="info"
              sx={{
                textAlign: "center",
                mb: 3,
                mt: 5,
              }}
            >
              Información de contacto{" "}
            </MKTypography>{" "}

          </Container>
        </Grid>{" "}
        <Cont />
        <MKBox
          zIndex={5}
          sx={{
            backgroundColor: ({ palette: { info }, functions: { rgba } }) =>
              rgba(info.main, 0.08), // Cambia el valor de 0.8 a 0.6 para hacerlo más oscuro
            backdropFilter: "blur(30px)",
            paddingY: "15vh",
            alignItems: "center", // Centra verticalmente
            justifyContent: "center", // Centra horizontalmente
          }}
        >
          <Container>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                lg={5}
                ml="auto"
                sx={{
                  textAlign: {
                    xs: "center",
                    lg: "left",
                  },
                }}
              >
                <img src={logo} alt="Servicios Medicos Empresariales LS" width="45%" />
              </Grid>{" "}
              <Grid
                item
                xs={12}
                lg={5}
                ml="auto"
                sx={{
                  textAlign: {
                    xs: "center",
                    lg: "left",
                  },
                }}
              >
                <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                  Gracias por tu preferencia!
                </MKTypography>{" "}
                <MKTypography variant="body1" color="text">
                  Soluciones médicas a tú medida{" "}
                </MKTypography>{" "}
              </Grid>{" "}
            </Grid>{" "}
          </Container>{" "}
        </MKBox>{" "}
      </Card>{" "}
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />{" "}
      </MKBox>{" "}
    </>
  );
}

export default Contacto;
